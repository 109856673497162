/**
 * 4. Site Header
 */
.site-header {
    padding-bottom: 4.16667em;
    padding-top: 3.88889em;
}

.site-header-inside {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.site-title {
    color: #fff;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.16667;
    margin: 0 20px 0.33333em 0;
    text-transform: uppercase;

    a {
        border-left: 0.27778em solid $accent;
        color: inherit;
        padding-left: 0.55555em;
        text-decoration: none;

        &:hover {
            color: $accent-alt;
        }
    }
}

.site-description {
    color: $gray;
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
    margin-bottom: 0.85714em;
    max-width: 480px;
}

@media only screen and (max-width: 960px) {
    .site-header {
        padding-bottom: 3.33333em;
        padding-top: 3em;
    }
}

@media only screen and (max-width: 480px) {
    .site-header {
        padding-bottom: 2.5em;
        padding-top: 2.25em;
    }

    .site-title {
        font-size: 1.55556em;
    }
}
