/**
 * 2. Structure
 */
.outer {
    padding-left: 15px;
    padding-right: 15px;
}

.inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.inner-small {
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
}

.site {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
}

.site-content {
    box-sizing: border-box;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
}

.primary {
    margin-bottom: 3.33333em;
}

.sidebar {
    font-size: 0.88889em;
    margin-bottom: 3.33333em;
}

@media only screen and (min-width: 501px) {
    .outer {
        padding-left: 3vw;
        padding-right: 3vw;
    }
}

@media only screen and (min-width: 961px) {
    .outer {
        padding-left: 30px;
        padding-right: 30px;
    }

    .site-main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .primary {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        margin-bottom: 1.66667em;
        margin-right: 3.333%;
        width: 75%;
    }

    .sidebar {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 1.66667em;
    }
}
