// Fonts
$font-primary: 'Lato', Helvetica, sans-serif;

// Colors
$accent:      #22b3eb;
$accent-alt:  #5ec5f0;
$gray-darker: #333;
$gray-dark:   #3d3d3d;
$gray:        #818181;
$gray-light:  #c6c6c6;
