/**
 * 3. Site Navigation
 */
.site-nav {
    background-color: $gray-dark;
  
    &:after {
        background: rgba(0,0,0,.6);
        content: "";
        height: 100vh;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        -webkit-transition: opacity .25s, visibility 0s .25s;
        transition: opacity .25s, visibility 0s .25s;
        visibility: hidden;
        width: 100%;
    }
}

.site-nav-inside {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.menu {
    font-size: 12px;
    line-height: 1.5;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-item {
    a {
        color: $gray;
        font-weight: 700;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            color: $accent;
        }
    }

    &.current {
        a {
            color: $accent;
        }
    }
}

.actions {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin-bottom: 0;
    margin-left: auto;
    padding: 0;

    .button {
        color: $gray-light;
        margin-left: 4px;
    }
}

@media only screen and (min-width: 801px) {
    #menu-show,
    #menu-hide {
      display: none;
    }

    .menu {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .menu-item {
        border-left: 2px solid rgba($gray,.15);
        margin: 1.33333em 0;
        padding-left: 15px;
        padding-right: 15px;
  
        &:first-child {
            border: 0;
            padding-left: 0;
        }
    }
}

@media only screen and (max-width: 800px) {
    #menu-show {
        background: 0;
        color: $gray;
        padding: 0 0 0 20px;
        position: relative;

        .icon-menu {
            left: 0;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .menu {
        padding: 1.25em 15px 3em;
    }

    .menu-item {
        border-top: 2px solid rgba($gray,.15);

        a {
            display: block;
            padding: 1.25em 0;
        }

        &:first-child {
            border: 0;
        }
    }

    .menu-panel {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-color: $gray-darker;
        bottom: 0;
        left: -100%;
        max-width: 360px;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0;
        -webkit-transition: left .3s cubic-bezier(0.65, 0.05, 0.36, 1), visibility 0s .3s;
        transition: left .3s cubic-bezier(0.65, 0.05, 0.36, 1), visibility 0s .3s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }

    .js-menu-active {
        overflow: hidden;

        .site-nav {
            &:after {
                opacity: 1;
                -webkit-transition: opacity .25s;
                transition: opacity .25s;
                visibility: visible;
                z-index: 1;
            }
        }

        .menu-panel {
            left: 0;
            -webkit-transition: left .3s cubic-bezier(0.65, 0.05, 0.36, 1);
            transition: left .3s cubic-bezier(0.65, 0.05, 0.36, 1);
            visibility: visible;
        }
    }

    .menu-panel-scrollable {
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position: relative;
    }

    .menu-panel-top {
        background-color: $gray-dark;
        text-align: right;
    }
}
